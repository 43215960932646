'use strict';

const helpers = require("./helpers");

const BREAKPOINTTABLETTE = 769;
const NAMESPACE_CLASS = 'terdav__';

var commons = {
	init: function init(settings) {
		/// Detect navigateur
		/// return true / false
		const NAVIGATOR = {
			'isiPad': /ipad/i.test(navigator.userAgent.toLowerCase()),
			'isSafari': /constructor/i.test(window.HTMLElement),
			'isMobileSafari': navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/),
			'isDeviceiPad': /safari/i.test(navigator.userAgent.toLowerCase()) && settings.helpers.getWindowWidth() === 768
		}
		///
		/// Initialisation des functions
		///
		this.convertButtonToLink();
		!settings.helpers.getPage('pays') && !settings.helpers.getPage('region') && this.tooltip();
		this.loader.loaded();
		this.configCss(settings, NAVIGATOR);

		if (!Boolean(settings.helpers.getWindowWidth() < BREAKPOINTTABLETTE) && document.getElementById('id1').value != 'listeresultats')
			this.flecheScrollTop();
		else if (document.getElementById('id1').value == 'listeresultats' && settings.helpers.getWindowWidth() > 1650) {
			this.flecheScrollTop();
		}

		let orientationScreen = settings.helpers.getOrientation();

		window.addEventListener('resize', function () {
			if (settings.helpers.isMobile() || settings.helpers.isTouchDevice()) {
				document.body.classList.add('is-touch');
				document.body.classList.remove('is-not-touch');
			} else {
				document.body.classList.add('is-not-touch');
				document.body.classList.remove('is-touch');
				document.body.classList.remove('touch');
			}

			let device = settings.helpers.isTouchDevice() ? 'touch' : 'not-touch';
			document.body.classList.add(device);

			if (NAVIGATOR.isMobileSafari) {
				if (orientationScreen !== settings.helpers.getOrientation()) {
					window.location.reload()
				}
			}
		});

		customElements.define('box-rgpd', rgpd);
		customElements.define('box-niveaux', niveaux);

	},

	convertButtonToLink: function (attribute = 'data-url') {
		let buttons = document.querySelectorAll(`[${attribute}]`);
		for (var i = buttons.length - 1; i >= 0; i--) {
			let url = buttons[i].getAttribute(attribute);

			buttons[i].addEventListener('click', function () {
				window.location.href = url;
			});
			/// Sur les LR Belge (#13768), on supprime l'attribut
			if (settingsApp.section === 'listeresultats') {
				delete buttons[i].dataset.voyage;
			}
		}
	},

	loader: {
		state: false,
		loaded: function () {
			document.body.classList.remove('loading');
			this.state = false;
		},
		loading: function () {
			document.body.classList.add('loading');
			this.state = true;
		}
	},

	tooltip: function (selector = '.tooltip', classContainerTooltip = 'tooltipBubble') {
		const tooltipList = document.querySelectorAll(selector);

		// Loop through that array
		tooltipList.forEach(function (el) {
			// Create a <label> element
			const tooltip = document.createElement('div')
			// Give it a class of 'tooltipBubble'
			tooltip.classList.add(classContainerTooltip)
			// Set the text inside that element to be our 'data-tooltip' value
			tooltip.innerHTML = el.dataset.tooltip
			// Then insert it in the element we're on in the loop
			el.appendChild(tooltip);


			el.addEventListener('click', function (btn) {
				if (window.ontouchstart !== undefined) {
					btn.target.classList.toggle('is-active');
				} else {
					if (btn.target.dataset.tooltipUrl !== undefined) {
						window.location.href = btn.target.dataset.tooltipUrl;
					}

				}
			})
		})
	},

	flecheScrollTop: function (a = '#uparrow', delay = 800) {
		let arrow = document.querySelector(a);
		const btnVisibility = () => {
			if (window.scrollY > 50) {
				arrow.style.visibility = "visible";
				arrow.classList.add('fadeIn');
			} else {
				arrow.style.visibility = "hidden";
				arrow.classList.remove('fadeIn');
			}
		};
		document.addEventListener("scroll", () => {
			btnVisibility();
		});
		arrow.addEventListener('click', function () {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		});
	},

	///
	/// Gestion desktop -- mobile
	///
	configCss: function (settings, navigator) {
		document.documentElement.classList.remove('nojs');
		let page = document.getElementById('id1').value;
		if (page === 'produit') {
			document.documentElement.classList.add('has-scroll-padding-top');
		}

		let device = settings.helpers.isTouchDevice() ? 'touch' : 'not-touch';
		document.body.classList.add(device);

		if (navigator.isSafari || navigator.isMobileSafari) {
			document.body.classList.add('safari');
		}
		if (navigator.isiPad || navigator.isDeviceiPad) {
			if (page != 'produit' && page != 'inscription' && page != 'relanceoption' && page != 'relancedevis' && page != 'relancesolde' && page != 'paiementlibre') {
				document.body.classList.add(NAMESPACE_CLASS + page + '--' + 'desktop');
				document.body.classList.add('isiPad');
			}
		}
	},

	ErreurJs: function (title, message, objectConfig) {

		let url = "/Application/ErreurJs",
			data = {
				title: title,
				message: message,
				objectConfig: objectConfig
			};

		fetch(url, {
				method: 'POST',
				headers: {
					'X-Robots-Tag': 'noindex, nofollow'
				},
				body: new URLSearchParams(data)
			})
			.catch(function (err) {
				// There was an error
				console.warn('Something went wrong.', err);
			});
	}
}
module.exports = commons;